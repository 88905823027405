import React from "react";
import Layout from "../../components/layout/layout";
import Check from "../../assets/svg/icons-feather/check.svg";
import Icon from "../../components/icon";
import {Link} from "gatsby";
import "../landing-page.scss";

const IntroverteKracht = () => {

  return (
    <Layout>
        <div className="landingpage-wrapper">

            <h1>Coachtraject De Kracht van de Introvert</h1>

            <div className="block green top">
              <div className="text">
                <p>Ben jij een introvert en <strong>worstel</strong> jij met dingen als:</p>

                <ul className="checklist">
                  <li><Check width="32" height="32"/>verkeerd begrepen worden</li>
                  <li><Check width="32" height="32"/>netwerken</li>
                  <li><Check width="32" height="32"/>dominante mensen</li>
                  <li><Check width="32" height="32"/>je te sterk aanpassen</li>
                  <li><Check width="32" height="32"/>vergaderen</li>
                  <li><Check width="32" height="32"/>gesprekken voeren</li>
                </ul>
              </div>
            </div>

            <div className="block with-bottom">
              <div className="pop"><Icon name="puzzlehead"/>Heb jij het gevoel dat er meer in je zit?</div>
              <div className="text">
              <p>Maar dat het er gewoonweg niet uit komt?</p>
              <p>Dan is dit <strong>coachtraject</strong> iets voor jou.</p>
              <p>Bekijk voor een korte introductie de video:</p>
              </div>
            </div>

            <iframe title="youtube" width="100%" height="330" src="https://www.youtube-nocookie.com/embed/BqZEmCftXGs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            <div className="block green top">
              <div className="text">
                
                <p>In dit programma <strong>leer</strong> je:</p>
                <ul className="checklist one-column">
                <li><Check width="32" height="32"/>waar jouw unieke kracht ligt als introvert</li>
                <li><Check width="32" height="32"/>hoe je omgaat met jouw uitdagingen</li>
                <li><Check width="32" height="32"/>hoe je jouw kracht beter kunt gaan benutten</li>
                <li><Check width="32" height="32"/>hoe je een plan maakt om jouw eigen toekomst te bepalen</li>
              </ul>
              </div>
            </div>

            <div className="block">
              <div className="pop">
                <Icon name="trust"/>Over mij
              </div>
              <div className="text left two-columns">
                <p>Als coach en trainer voor introverten zoek ik graag de <strong>diepgang</strong> op. Ik geloof namelijk niet dat je als coach aan symptoombestrijding zou moeten doen. Wanneer je meer de diepte in gaat, kom je bij de echte bron van datgene wat jou tegenhoudt en kun je het echt aanpakken.</p>
                <p>Die diepgang is ook precies wat ik in deze training heb gestopt. Je leert meer over wat introversie precies is en krijgt daarnaast talloze modellen en handvatten om zelf aan de slag te kunnen gaan. Uiteraard <strong>met begeleiding</strong> van mij, wanneer je daar&nbsp;behoefte aan hebt.</p>
                <p>De uitdagingen van de introvert ken ik zelf maar al te goed. Ik voelde me <strong>jarenlang onbegrepen</strong> en snapte niet goed hoe ik mijn talenten beter naar voren kon laten komen. Door veel aan mezelf te werken (onder andere door een MBA te doen en met een coach te werken) kreeg ik eindelijk de handvatten om dit wel te kunnen. Die deel ik nu graag met anderen.</p>
                <p>Lees nog veel meer <Link to="/nl/about">over mij</Link>.</p>
              </div>
            </div>  


            <div className="block">
              <div className="pop">
                <Icon name="finding"/>De training
              </div>
              <div className="text left two-columns">
                <p>Het traject bestaat uit <strong>video's</strong> met zowel theorie als praktijk, <strong>oefeningen</strong> die je helpen om de diepte in te gaan en daarnaast <strong>vier coachgesprekken van anderhalf uur</strong> waarin we één op één zullen ingaan op de dingen die jij tegenkomt tijdens de training.&nbsp;</p>

                <p>Je kunt dit traject in jouw eigen tempo doorlopen, waarbij jij zelf bepaalt wanneer je welke modules doet en wanneer je de coachgesprekken wilt voeren. Je krijgt <strong>een half jaar toegang tot de leeromgeving</strong>, zodat je ook na het doorlopen van alle modules nog eens terug kunt kijken.&nbsp;</p>

              </div>
            </div>  



            <div className="block yellow">
              <div className="pop">
                <Icon name="badge"/>Wat je krijgt
              </div>
              <div className="text left two-columns">
                <p>Voor dit traject betaal je &euro; 995 in combinatie met online coaching en &euro; 1195 in combinatie met live coaching.</p>
          
               <p>Prijzen zijn inclusief BTW.</p>

                <div className="nobreak">
                  <ul className="checklist one-column">
                    <li><Check width="32" height="32"/>28 video's</li>
                    <li><Check width="32" height="32"/>jouw persoonlijke <strong>Big Five</strong> persoonlijkheidsrapport</li>
                    <li><Check width="32" height="32"/>24 reflectievragen</li>
                    <li><Check width="32" height="32"/>7 verdiepingsopdrachten</li>
                    <li><Check width="32" height="32"/>een overzicht van waar jij goed in bent</li>
                    <li><Check width="32" height="32"/>een plan voor jouw toekomst</li>
                    <li><Check width="32" height="32"/>4 <strong>coachsessies</strong> van elk anderhalf uur</li>
                  </ul>
                </div>
              </div>
            </div>  

            <div className="block">
              <div className="pop">
                <Icon name="detail"/>Opbouw in detail
              </div>
              <div className="text left two-columns">

              <ol>
              <li><strong>Introductie</strong>
              <ol>
                <li>Welkom bij deze e-learning</li>
              </ol>
              </li>
              <li><strong>Wat is introversie?</strong>
              <ol>
                <li>De basis</li>
                <li>De benadering van MBTI</li>
                <li>De Big Five</li>
                <li>De neurowetenschap</li>
                <li>Vooroordelen over introversie</li>
              </ol>
              <li><strong>De uitdagingen van de introvert</strong>
              <ol>
                <li>Inleiding</li>
                <li>Netwerken</li>
                <li>Vergaderen</li>
                <li>Gesprekken voeren</li>
                <li>Dominante mensen</li>
                <li>Verkeerd begrepen worden</li>
                <li>Te sterk aanpassen</li>
                <li>Gezien worden​​​​​​​</li>
              </ol>
              </li>
              <li><strong>De kracht van de introvert</strong>
              <ol>
                <li>Je kracht kennen</li>
                <li>Jouw zelfportret</li>
                <li>Jouw blinde vlek​​​​​​​</li>
              </ol>
              </li>
              <li><strong>Je kracht inzetten</strong>
              <ol>
                <li>Je kracht benutten</li>
                <li>Plannen en prioriteiten stellen</li>
                <li>Je energie in balans</li>
                <li>Jouw persoonlijke groeiplan​​​​​​​</li>
              </ol>
              </li>
              <li><strong>Afronding en evaluatie</strong>
              <ol>
                <li>Bedankt</li>
                <li>Evaluatie</li>
              </ol>
              </li>
              </li>
            </ol>
              </div>
            </div>  

            <div className="block yellow">
              <div className="text one-column">
             
                <p>Niet tevreden? Dan kun je binnen zeven dagen je geld terugvragen.</p>
              </div>
            </div>  


            <div className="block">
              <div className="pop">
                <Icon name="question"/>Veelgestelde vragen
              </div>
              <div className="text left one-column">
                <p><strong>Ik weet niet zeker of ik introvert ben. Is dit traject &nbsp;dan iets voor mij?</strong></p>
                <p>Het traject geeft je meer informatie over wat introversie is en hoe je het kunt meten. Onderdeel daarvan is de Big Five persoonlijkheidstest, die je zal vertellen of je al dan niet introvert bent. De training kan dus in ieder geval antwoord geven op die vraag.</p>
                <p><strong>Heb ik wel coaching nodig?</strong></p>
                <p>De ervaring leert dat deelnemers aan dit traject het fijn vinden om hun voortgang tijdens deze training persoonlijk te bespreken. Dat zorgt er namelijk voor dat ze er meer uit halen en ook meer leren over zichzelf en hoe ze bepaalde zaken aan kunnen pakken. Daarnaast zorgt het voor commitment, zodat je zeker weet dat je de training ook daadwerkelijk af zult ronden.&nbsp;</p>
                <p><strong>Is het het geld waard?</strong></p>
                <p>Diverse (test)deelnemers gaven aan dit traject als zeer waardevol te hebben ervaren. Het gaf hen antwoorden op vragen die ze hadden en heeft hen geholpen om zelf stappen voorwaarts te kunnen zetten.&nbsp;</p>
                <p>Naast online toegang krijg je bij dit traject ook zes uur coaching. Door die combinatie kun je het geleerde echt toepassen op je eigen situatie en aan de slag gaan met de dingen waar jij tegenaan loopt. Op die manier wordt het dus een stuk persoonlijker. Voor zes uur coaching betaal je bij mij normaal gesproken 594 euro.</p>
                <p>Uiteraard is de ervaring voor iedereen verschillend en zal het zwaartepunt ook voor iedereen ergens anders liggen. Bevalt de manier van werken je toch niet? Dan kun je binnen zeven dagen na aanmelding je geld terugvragen (als je nog niet alle modules en coaching hebt doorlopen)</p>
                <p><strong>Gaat dit mij echt helpen?</strong></p>
                <p>Als je op zoek bent naar meer inzicht in jouw introversie en daarnaast wilt leren hoe je omgaat met jouw uitdagingen en hoe je jouw kracht beter in kunt zetten, dan is deze training zeker van toegevoegde waarde voor jou. Bij coaching is het echter wel belangrijk dat jij zelf gemotiveerd bent om aan de slag te gaan met de dingen die je onderweg tegenkomt. Alleen dan zul je daadwerkelijk de resultaten behalen waar je naar op zoek bent.&nbsp;</p>
              </div>
            </div>  

            <div className="block yellow">
              <div className="text one-column">
                <p><strong>Ben jij klaar om aan de slag te gaan?</strong></p>
                <p>Neem <a href="/nl/contact">contact op</a> voor een vrijblijvend gesprek.</p>
              </div>
            </div>  


      </div>
    </Layout>
  )
};

export default IntroverteKracht;
